<template>
  <div class="medium-12 columns">
    <div v-if="getTemplateHeader">
      <label>{{ $t('WHATSAPP_TEMPLATES.PARSER.HEADER') }}</label>

      <textarea
        v-if="formatTypeTemplateHeader === 'TEXT' && processedStringHeader"
        v-model="processedStringHeader"
        rows="4"
        readonly
        class="template-input"
      />
    </div>

    <div>
      <label>{{ $t('WHATSAPP_TEMPLATES.PARSER.BODY') }}</label>
      <textarea
        v-model="processedStringBody"
        rows="4"
        readonly
        class="template-input"
      />
    </div>

    <div v-if="variablesHeader" class="template__variables-container">
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_HEADER') }}
      </p>

      <div
        v-for="(variable, key) in processedParamsHeader"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ key }}
        </span>
        <woot-input
          v-model="processedParamsHeader[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
        />
      </div>
    </div>

    <div v-if="variablesBody" class="template__variables-container">
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_BODY') }}
      </p>

      <div
        v-for="(variable, key) in processedParamsBody"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ key }}
        </span>
        <woot-input
          v-model="processedParamsBody[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
          @blur="onBlur(processedParamsBody)"
        />
      </div>

      <p v-if="$v.$dirty && $v.$invalid" class="error">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE') }}
      </p>
    </div>

    <footer class="custom-footer">
      <woot-button @click="sendMessage">
        {{ $t('CAMPAIGN.NEXT.BUTTON_TEXT') }}
      </woot-button>
    </footer>
  </div>
</template>

<script>
import templateWppMixin from 'shared/mixins/templateWppMixin';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin, templateWppMixin],
  props: {
    template: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    sendMessage() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const payload = {
        message: this.getMessage(),
        processed_params: this.getProcessedParams(),
      };

      this.$emit('sendMessage', payload);
    },
  },
};
</script>

<style scoped lang="scss">
.template__variables-container {
  padding: var(--space-one);
}

.variables-label {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-one);
}

.template__variable-item {
  align-items: center;
  display: flex;
  margin-bottom: var(--space-one);

  .label {
    font-size: var(--font-size-mini);
  }

  .variable-input {
    flex: 1;
    font-size: var(--font-size-small);
    margin-left: var(--space-one);
  }

  .variable-label {
    background-color: var(--s-75);
    border-radius: var(--border-radius-normal);
    display: inline-block;
    font-size: var(--font-size-mini);
    padding: var(--space-one) var(--space-medium);
  }
}

footer {
  display: flex;
  justify-content: flex-end;

  &.custom-footer {
    position: absolute;
    right: var(--space-large);
    margin-top: var(--space-small);
  }

  button {
    margin-left: var(--space-one);
  }
}

.error {
  background-color: var(--r-100);
  border-radius: var(--border-radius-normal);
  color: var(--r-800);
  padding: var(--space-one);
  text-align: center;
}

.template-input {
  background-color: var(--s-25);
}
</style>
