<template>
  <div v-if="item">
    <p v-html="getContentParameterization"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const HEADER = 'HEADER';
const BODY = 'BODY';

export default {
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  methods: {
    replaceContent(content, type) {
      const { parameterizations } = this.item;

      parameterizations
        .filter(param => param.type === type)
        .forEach(({ key, value }) => {
          content = content.replace(key, value);
        });

      return content;
    },
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
    getTemplate() {
      const { inbox, template } = this.item;

      return this.inboxes
        .find(({ id }) => inbox.id === id)
        .message_templates.find(({ id }) => template.code === id);
    },
    getTemplateHeader() {
      return this.getTemplate.components.find(({ type }) => type === HEADER);
    },
    templateStringHeader() {
      return this.getTemplateHeader?.text ?? '';
    },
    templateStringBody() {
      return this.getTemplate.components.find(({ type }) => type === BODY).text;
    },
    getContentParameterization() {
      let text = '';

      if (this.templateStringHeader) {
        text += this.replaceContent(this.templateStringHeader, HEADER);
        text += '<br>';
      }

      if (this.templateStringBody) {
        text += this.replaceContent(this.templateStringBody, BODY);
      }

      return text;
    },
  },
};
</script>