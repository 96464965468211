<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="$t('TEMPLATE_PARAMETERIZATION.ADD.TITLE')"
      :header-content="$t('TEMPLATE_PARAMETERIZATION.ADD.DESCRIPTION')"
    />

    <timeline :current_page="page.current" :total="page.total" />

    <form class="row" @submit.prevent="submit">
      <div class="medium-12 columns">
        <div v-if="page.current === 1">
          <label :class="{ error: $v.selectedTemplate.$error }">
            {{ $t('TEMPLATE_PARAMETERIZATION.ADD.FORM.TEMPLATE.LABEL') }}

            <templates-picker
              v-if="!selectedTemplate"
              :inbox-id="inboxId"
              @onSelect="pickTemplate"
            />

            <span v-if="$v.selectedTemplate.$error" class="message">
              {{ $t('TEMPLATE_PARAMETERIZATION.ADD.FORM.TEMPLATE.ERROR') }}
            </span>
          </label>
        </div>

        <div v-if="page.current === 2">
          <template-parser
            v-if="selectedTemplate"
            :template="selectedTemplate"
            @sendMessage="getEventTemplate"
          />
        </div>

        <div v-if="page.current === 3">
          <textarea
            rows="4"
            v-model="message"
            readonly
            class="template-input"
          />
        </div>
      </div>

      <div class="modal-footer">
        <woot-button
          @click.prevent="changePage('minus')"
          :isDisabled="canDisableButton"
        >
          {{ $t('GENERAL_SETTINGS.BACK') }}
        </woot-button>

        <woot-button v-if="canChangePage" @click.prevent="changePage('add')">
          {{ $t('TEMPLATE_PARAMETERIZATION.NEXT.BUTTON_TEXT') }}
        </woot-button>

        <woot-submit-button
          v-else
          type="submit"
          :button-text="$t('TEMPLATE_PARAMETERIZATION.ADD.CREATE_BUTTON_TEXT')"
          :loading="uiFlags.isCreating"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker';
import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParserWithParameterization';
import Timeline from 'shared/components/Timeline';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    TemplatesPicker,
    TemplateParser,
    Timeline,
  },
  mixins: [alertMixin],
  props: {
    inboxId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      message: null,
      selectedTemplate: null,
      templateParams: null,
      parameterizations: [],
      page: {
        init: 1,
        end: 3,
        total: 3,
        current: 1,
      },
    };
  },
  validations() {
    return {
      selectedTemplate: { required },
      message: { required },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'templateParameterizations/getUIFlags',
    }),
    canChangePage() {
      return this.page.current !== this.page.end;
    },
    canDisableButton() {
      return (
        this.page.current === this.page.init ||
        (this.page.current === this.page.end && this.uiFlags.isCreating)
      );
    },
  },
  methods: {
    ...mapActions('templateParameterizations', ['create']),
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      try {
        const data = this.getParameterizationDetails();

        await this.create({ template_parameterizations: data });

        this.$emit('on-created');

        this.showAlert(
          this.$t('TEMPLATE_PARAMETERIZATION.ADD.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        console.log(error.message);
        const errorMessage = this.$t(
          'TEMPLATE_PARAMETERIZATION.ADD.API.ERROR_MESSAGE'
        );
        this.showAlert(errorMessage);
      }
    },
    pickTemplate(template) {
      this.selectedTemplate = template;
      this.changePage('add');
    },
    getEventTemplate(event) {
      const { message, processed_params } = event;

      this.message = message;
      this.templateParams = processed_params;

      this.changePage('add');
    },
    getParameterizationDetails() {
      const parameterizations = [];

      const header = Object.values(this.templateParams.header);
      const body = Object.values(this.templateParams.body);

      if (header.length) {
        parameterizations.push({
          key: '{{1}}',
          type: 'HEADER',
          value: header.pop(),
        });
      }

      if (body.length) {
        body.forEach((item, index) => {
          const key = `{{${index + 1}}}`;
          parameterizations.push({ key, type: 'BODY', value: item });
        });
      }

      return {
        inbox_id: this.inboxId,
        template_id: this.selectedTemplate.id,
        parameterizations,
      };
    },
    changePage(type) {
      if (type === 'minus' && this.page.current > this.page.init) {
        if (this.page.current === this.page.end - 1) {
          this.selectedTemplate = null;

          this.$v.$reset();
        }

        --this.page.current;

        this.message = null;
        this.templateParams = null;
      }

      if (type === 'add' && this.page.current < this.page.end) {
        this.$v.$touch();

        if (
          (this.page.current === 1 && this.$v.selectedTemplate.$error) ||
          (this.page.current === 2 && this.$v.message.$error)
        ) {
          return;
        }

        this.$v.$reset();

        ++this.page.current;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.message {
  margin-top: var(--space-micro);

  &.custom-error {
    position: absolute;
    margin-top: var(--space-jumbo);
    left: var(--space-large);
    width: 50%;
  }
}

.toggle-button {
  margin-bottom: var(--space-normal);
}

.modal-footer {
  button {
    margin-left: 0 !important;
  }

  width: 100%;
  justify-content: space-between !important;
}
</style>