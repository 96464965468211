<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="$t('SCHEDULED_MESSAGE.ADD.TITLE')"
      :header-content="$t('SCHEDULED_MESSAGE.ADD.DESCRIPTION')"
    />
    <!-- @TODO -->
    <form class="row" @submit.prevent="submit">
      <div class="medium-12 columns">Oi</div>

      <div class="modal-footer">
        <woot-button> Ok </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  methods: {
    async submit() {
      console.log('Ok');
    },
    onClose() {
      this.$emit('on-close');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-footer {
  button {
    margin-left: 0 !important;
  }

  width: 100%;
  justify-content: space-between !important;
}
</style>