<template>
  <div class="settings--content">
    <div class="table-actions-wrap">
      <div class="right-aligned-wrap">
        <woot-button
          color-scheme="success"
          icon="whatsapp"
          @click="openAddPopup"
        >
          {{ $t('TEMPLATE_PARAMETERIZATION.HEADER') }}
        </woot-button>
      </div>
    </div>

    <div class="medium-12">
      <table-template
        :items="getParameterizations"
        :show-search-empty-state="showEmptySearchResult"
        :is-loading="uiFlags.isFetching"
        @on-delete-click="openDeletePopup"
      />

      <table-footer
        :current-page="Number(meta.current_page)"
        :total-count="meta.count"
        :page-size="5"
        @page-change="onPageChange"
      />
    </div>

    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup" size="low">
      <add-template-parameterization
        :inbox-id="inbox.id"
        @on-created="onCreateParameterization"
        @on-close="hideAddPopup"
      />
    </woot-modal>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.TITLE')"
      :message="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.YES')"
      :reject-text="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.NO')"
      :is-loading="uiFlags.isDeleting"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';

import TableTemplate from './list/Table';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import AddTemplateParameterization from './create/AddTemplateParameterization';

export default {
  components: {
    TableTemplate,
    TableFooter,
    AddTemplateParameterization,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      page: 1,
      sortConfig: 'id',
      showAddPopup: false,
      selectedParameterization: {},
      showDeleteConfirmationPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      meta: 'templateParameterizations/getMeta',
      getParameterizations: 'templateParameterizations/getParameterizations',
      uiFlags: 'templateParameterizations/getUIFlags',
    }),
    showEmptySearchResult() {
      return this.getParameterizations.length === 0;
    },
  },
  methods: {
    ...mapActions('templateParameterizations', ['get', 'search', 'delete']),
    async getTemplateParameterizations() {
      try {
        if (!this.inbox.id) return;

        const params = {
          page: this.page,
        };

        await this.get(params);
      } catch (error) {
        this.showAlert(error.message);
      }
    },
    async deleteParameterization(id) {
      try {
        await this.delete(id);

        this.showAlert(
          this.$t('INBOX_MGMT.TEMPLATES.DELETE.API.SUCCESS_MESSAGE')
        );

        this.clearData();
        this.getTemplateParameterizations();
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.TEMPLATES.DELETE.API.ERROR_MESSAGE')
        );
      } finally {
        this.closeDeletePopup();
      }
    },
    onCreateParameterization() {
      this.hideAddPopup();
      this.clearData();
      this.getTemplateParameterizations();
    },
    onPageChange(page) {
      this.page = Number(page);

      this.getTemplateParameterizations();
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedParameterization = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      const { id } = this.selectedParameterization;

      this.deleteParameterization(id);
    },
    clearData() {
      this.page = 1;
      this.sortConfig = 'id';
    },
  },
  mounted() {
    this.getTemplateParameterizations();
  },
};
</script>

<style lang="scss" scoped>
.table-actions-wrap {
  display: flex;
  justify-content: end;
  align-items: center;

  .right-aligned-wrap {
    margin-top: var(--space-normal);
    margin-bottom: var(--space-normal);
  }
}
</style>