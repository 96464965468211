<template>
  <div>
    <woot-modal :show.sync="showPopupDetails" :on-close="hidePopupDetails">
      <show @on-close="hidePopupDetails" />
    </woot-modal>

    <woot-modal :show.sync="showPopupCreate" :on-close="hidePopupCreate">
      <create @on-close="hidePopupCreate" />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Create from './Create';
import Show from './Show';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    Create,
    Show,
  },
  mixins: [alertMixin],
  props: {
    showPopupCreate: {
      type: Boolean,
      default: false,
    },
    showPopupDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getScheduled: 'scheduledMessages/getScheduled',
    }),
  },
  methods: {
    hidePopupCreate() {
      this.$emit('hide-popup-create');
    },
    hidePopupDetails() {
      this.$emit('hide-popup-details');
    },
  },
};
</script>